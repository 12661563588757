/// <reference types="typescript" />

class ScrollDown {
	private button: JQuery;
	private animationLength: number = 800;

	moveStack: Array<Object> = [];
	isMoving: boolean = false;

	isHidden: boolean = false;

	constructor(protected selector = '.scroll-button') {
		this.init();
	}

	init(): void {
		this.button = $(this.selector);
		this.button.on('click', (e) => this.scroll(e));

		const jWindow = $(window);
		const jBody = $('body');
		jWindow.on('scroll', (e: JQuery.ScrollEvent) => {
			if (jWindow.height() * 1.2 + jWindow.scrollTop() >= jBody.height()) {
				if (!this.isHidden) {
					this.button.addClass('hide');
					this.isHidden = true;
				}
			} else if (this.isHidden) {
				this.button.removeClass('hide');
				this.isHidden = false;
			}
		})
	}

	private static pageHeight() {
		return $(window).height();
	}

	private scroll(e: JQuery.ClickEvent = null) {
		if (this.isMoving) {
			this.moveStack.push({});

			return;
		}

		this.isMoving = true;

		const scrollTop = $(window).scrollTop();

		$('body,html').animate({
			scrollTop: scrollTop + ScrollDown.pageHeight() * 0.9
		}, this.animationLength, () => {
			this.isMoving = false;

			if (this.moveStack.length > 0) {
				const previousEvent = this.moveStack.pop();

				this.scroll();
			}
		});
	}
}

$(document).ready(() => {
	const scroll = new ScrollDown();
});
